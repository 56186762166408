table {
    width: 100%;
    border: 1px solid white;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.20);
    padding: 15px;
    backdrop-filter: saturate(180%) blur(20px);
    th{
        text-align: left;
        padding: 5px;
    }
}

