.live{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    iframe{
        border-radius: 10px;
        box-shadow: 0px 6px 10px rgba(0,0,0,0.3);
        max-width: 350px;
        max-height: 202px;
    }
    h1 {
        text-align: center;
    }
    a {
        color: white;
        text-decoration: none;
    }
}
.logout{
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    border: 0px;
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    &:hover {
        text-decoration: underline;
    }
}

@media only screen and (min-width: 992px) {
    .live{
        iframe{
            min-width: 768px;
            min-height: 430px;
            width: 768px;
            height: 430px;
        }
    }
}