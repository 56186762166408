@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;700&display=swap');

*{
  font-family: 'Oswald', sans-serif;
}

body {
  background: url('assets/img/background.webp') no-repeat top center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  padding-left: 10%;
  padding-right: 10%;
}

