.login__card{
    margin: auto;
    max-width: 500px;
    padding: 50px 25px;
    background-color: rgba(255, 255, 255, 0.70);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 10px;
    form {
        display: grid;
        input {
            font-size: 18px;
            height: 40px;
            padding: 10px;
            margin: 5px 0px;
            background-color: transparent;
            border: 1px solid rgb(65, 65, 65);
            border-radius: 10px;
            outline: none;
        }
        button {
            margin-top: 20px;
            font-size: 18px;
            height: 40px;
            background-image: linear-gradient(160deg, #9d6c9fbb 0%, #2e3192c9 100%);
            border: 0px solid #9d6c9f;
            color: white;
            border-radius: 10px;
            transition: .2s ease-out;
            cursor: pointer;
            &:hover{
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
                transition: .2s ease-in;
            }
        }
    }
}

.login {
        font-size: 18px;
        height: 40px;
        width: 50%;
        max-width: 250px;
        background-image: linear-gradient(160deg, #9d6c9fbb 0%, #2e3192c9 100%);
        border: 0px solid #9d6c9f;
        color: white;
        border-radius: 10px;
        transition: .2s ease-out;
        cursor: pointer;
        &:hover{
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
            transition: .2s ease-in;
        }
}