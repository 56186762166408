.countdown{
    display: flex;
    margin: 20px auto;
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0px 20px;
        font-size: 42px;
        .label {
            font-size: 18px;
        }
    }
}

.sublabel{
    font-size: 14px;
}

.flag {
    width: 20px;
}